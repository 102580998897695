<template>
  <div>
    <div id="top">
      <el-row style="
    overflow: scroll;
    height: 740px;
">
        <el-col :span="8"
                style="width:480px">
          <el-row>
            <div class="image">
              <div>
                <div class="label">
                  <span class="text">审核答题卡</span>
                  <el-tag v-if='status==1'
                          style="margin-left:10px"
                          type="success">审核成功</el-tag>

                  <div class='again-btn'
                       v-if='status!=1&&!isTest'>
                    <el-button type='primary'
                               @click="getDetail(sheet_id, paper_id, status)">识别答题卡</el-button>
                  </div>
                  <div class='again-btn'
                       v-if='status!=1&&!isTest'>
                    <el-button type='primary'
                               v-show="false"
                               @click="showUploadDialog">重新上传答题卡</el-button>
                  </div>
                </div>
                <!-- <div class="label">正面</div> -->
                <!-- {{form }} -->
                <!-- width:fixed_1_width + 'px',
                      height:fixed_1_height + 'px' -->
                <div v-if='form.urllist'>
                  <el-image class="priview"
                            v-for="(item,index) in form.urllist"
                            :key="index"
                            :src="item"
                            lazy
                            :preview-src-list='Object.values(form.urllist)'>
                  </el-image>
                </div>
                <div class="priview"
                     v-else>加载失败，请重新上传答题卡!</div>

              </div>
              <!-- <div style="margin-top:16px"
                   v-show="form.image2"> -->
              <!-- <div class="label">反面</div> -->
              <!-- {{form }} width:fixed_1_width + 'px',
                      height:fixed_1_height + 'px'-->
              <!-- <el-image class="priview"
                          :style="{
                      }"
                          v-if='form.image2!=undefined'
                          :src="getUrl2"
                          lazy
                          :preview-src-list='previewList'>
                </el-image>
                <div class="priview"
                     v-else>加载失败，请重新上传答题卡!</div>
              </div> -->
              <!-- <div class='again-btn'
                 v-if='!form.status==1'>
              <el-button type='primary'
                         @click="showUploadDialog">重新上传答题卡</el-button>
            </div> -->
            </div>

          </el-row>
          <div class="topicImage"
               v-if="false">
            <div class="label">题目图片
            </div>
            <div class='imageWrap'>
              <div v-show="topicNumber">{{topicNumber}}</div>
              <div v-if='content&&Array.isArray(content)&&content.length>0'>
                <!-- <img v-for='item,index in content'
                   :key='index'
                   src="item"
                   alt=""> -->

                <el-image v-for='item,index in content'
                          :key='index'
                          :src="item"
                          alt=""
                          lazy
                          class="img_page"
                          :preview-src-list='previewImg'></el-image>
              </div>

              <div v-else-if='content'>
                <el-image :src="content"
                          alt=""
                          lazy
                          class="img_page"
                          :preview-src-list='previewImg'></el-image>
              </div>
            </div>
            <div style="margin-top:10px">
              <el-pagination background
                             layout="total,prev, pager, next,jumper"
                             :page-size='1'
                             :total="ImgList.length"
                             :current-page='currentPage'
                             @current-change="currentChange">
              </el-pagination>
            </div>
          </div>

          <el-row>

          </el-row>
        </el-col>
        <el-col :span="13">
          <div class='right'>

            <el-form ref="formRef"
                     :inline="true"
                     label-width="80px"
                     v-if="JSON.stringify(form)!='{}'"
                     style='overflow:hidden;margin-bottom:20px;padding-right:10px;width:100%;height:100%;'>
              <!-- 基本信息 -->
              <div v-for="(item,index) in infoImg"
                   style="margin-bottom:105px"
                   :key='index+item'>

                <el-form-item :label="infoObj[item.name]"
                              prop='ticket_number'
                              :class="{'active_topic':topicActiveId==index+1,'errorInfo': form.error && form.error.hasOwnProperty(item.name)}">
                  <el-input clearable
                            placeholder="请输入"
                            id='ticket_number'
                            v-model="form[item.name]"
                            @focus="topicContent(form.pq[item.name],index+1)" />
                </el-form-item>

              </div>
              <!-- <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="准考证ID"
                              prop='ticket_number'
                              :class="{'active_topic':topicActiveId==1}">
                  <el-input clearable
                            :readonly='form.status==1'
                            placeholder="请输入准考证ID"
                            id='ticket_number'
                            v-model="form.ticket_number"
                            @focus="topicContent(form.pq.rn,1)" />
                </el-form-item>
              </el-col>
            </el-row> -->

              <!-- <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="试卷ID"
                              prop='edu_paper_id'
                              :class="{'active_topic':topicActiveId==2}">
                  <el-input clearable
                            :readonly='form.status==1'
                            placeholder="试卷ID"
                            id='edu_paper_id'
                            v-model="form.edu_paper_id"
                            @focus="topicContent(form.pq.B,2)" />
                </el-form-item>
              </el-col>
            </el-row> -->
              <!-- <el-row :gutter="10">
              <el-col :span="24">
                <el-form-item label="用户ID"
                              prop='user_id'
                              :class="{'active_topic':topicActiveId==2}">
                  <el-input clearable
                            placeholder="请输入用户ID"
                            :readonly='form.status==1'
                            v-model="form.user_id"
                            id='user_id'
                            @focus="topicContent(form.pq.uid,2)" />
                </el-form-item>
              </el-col>
            </el-row> -->
              <div style="margin-top:60px"
                   id="xzt">
                <!-- 选择题 -->
                <div style="font-family: 'Medium';margin-bottom: 24px;;"
                     v-if="form.question_data">选择题</div>
                <el-row :gutter="10"
                        v-for="(item,key,index) in form.question_data"
                        :key="index+infoLength"
                        v-show="!isTest">
                  <el-col :span="24">
                    <el-form-item :label="key+':'"
                                  :prop='key'>
                      <el-input clearable
                                placeholder="请输入"
                                :class="{'errorInfo': form.error && form.error.hasOwnProperty(key) && !Object.values(form.error[key]).includes('HOLD'),'maybeError':form.error &&form.error.hasOwnProperty(key) && Object.values(form.error[key]).includes('HOLD')}"
                                :id='key'
                                v-model='form.question_data[key]' />
                      <!-- @focus="topicContent(form.pq.D,infoLength+1)" -->
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>

              <!-- 大题 -->
              <!-- <el-row :gutter="10"
                    v-for="(item,index) in imageTopic"
                    :key="item.index">
              <el-col :span="16">
                <el-form-item :label="item.name"
                              :prop="item.name"
                              :class="{'active_topic':topicActiveId==infoLength+1+index}">
                  <el-input clearable
                            :id="item.name"
                            :placeholder="'第'+item.name+'题'"
                            readonly
                            @focus="topicContent(item.value,infoLength+1+index,item.name)" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label-width="0"
                              :class="{'active_topic':topicActiveId==infoLength+1+index}">
                  <el-input-number v-model="item.score"
                                   style="width:100%"
                                   placeholder="分数"
                                   :min='0'
                                   v-show="form.scores&&form.scores.length!=0&&JSON.stringify(form.scores)!='{}'"
                                   :readonly='form.status==1'
                                   controls-position="right"></el-input-number>
                </el-form-item>
              </el-col>
            </el-row> -->
            </el-form>

          </div>
        </el-col>

      </el-row>
      <div id="savebtn">
        <el-button type="primary"
                   @click="save">审核通过</el-button>
      </div>
    </div>

    <!-- <img class="fixed_1"
         :src="form.image+'?random='+Math.random()"
         alt=""> -->
    <uploadDialog ref="dialogRef"
                  :showys="true"
                  :showysbar="false" />
  </div>
</template>

<script>
import { uploadImageAuto, uploadAgainSheet } from '@/api/uploadHn.js'
import { auditSheet, confirmSheet, sheetDatail, uploadSheetSchedule, getTestCardInfo, singleAuditSheet, detBatchTaskid, taskStatus } from '@/api/sheetHn.js'
import { loadings } from '@/utils/http.js'
import uploadDialog from './uploadDialog.vue'

export default {
  data () {
    return {
      form: {}, // 答题卡数据
      // editform:{},//答题卡表单
      previewList: [],// 答题卡预览，用于点击查看大图

      fixed_1_width: 580,
      fixed_1_height: 380,

      // isActive: "front",// 选中正面/反面
      content: '',// 当前题目内容
      previewImg: [],// 题目图片预览，用于点击查看大图
      topicActiveId: undefined,// 当前题目高亮
      currentPage: 1,
      size: 10,
      totalElements: 0,

      imageTopic: [], //大题图片
      infoImg: [], // 信息及选择题图片
      ImgList: [],// 图片列表
      topicNumber: '',
      sheet_id: '',
      paper_id: '',
      status: '',
      isRecognize: false,
      infoLength: 0,
      //个人信息个数
      infoObj: {
        "student_name": "姓名:",
        "rn": "准考证号:",
        "rn_hand": "准考证Id（手写）",
        "uid": "用户id"
      },
      startLoading: 0,
      sitv: undefined,//是否加载中定时器
      isTest: false //测试答题卡

    }
  },
  components: {
    // Sign
    uploadDialog
  },
  computed: {
    getUrl1 () {
      return this.form.image ? this.form.image : this.form.url
    },
    getUrl2 () {
      return this.form.image2 ? this.form.image2 : this.form.url2
    },
  },
  mounted () {
    // document.getElementsByClassName('view_warp')[0].style.background = 'none'
    // 是否测试答题卡
    if (this.$route.query.isTest) {
      this.isTest = true
      this.isRecognize = true
      console.log('test')
      this.getTestInfo()
    }
    else {


      this.sheet_id = this.$route.query.sheet_id
      this.paper_id = this.$route.query.paper_id
      this.status = this.$route.query.status

      // console.log(this.form)
      this.getPicFir()
      if (Number(this.sheet_id)) {
        this.getDetail(this.sheet_id, this.paper_id, this.status)
      }
    }
  },
  methods: {
    async getTestInfo () {
      let testCardObj = JSON.parse(window.sessionStorage.getItem("testCard"))
      const { data } = await getTestCardInfo(testCardObj)
      console.log(data)
      // this.form = data
      this.getDetail(this.sheet_id, this.paper_id, this.status, data)
    },
    getPicFir () {

      let urlObj = JSON.parse(window.localStorage.getItem("cardUrlObj"))
      // this.form.image = urlObj.url
      // this.form.image2 = urlObj.url2
      this.$set(this.form, 'image', urlObj.url)
      this.$set(this.form, 'image2', urlObj.url2)

      this.previewList.push(this.form.image, this.form.image2)

      var img1 = document.getElementsByClassName('fixed_1')[0]

      // img1.onload = () => {
      //   console.log(img1.clientWidth)
      //   if (img1.clientWidth < img1.clientHeight) {
      //     this.fixed_1_width = 380
      //     this.fixed_1_height = 580

      //   } else {
      //     this.fixed_1_width = 580
      //     this.fixed_1_height = 380
      //   }

      //   console.log(11)
      // }
    },

    async save () {
      if (this.isTest) {
        this.$router.go(-1)
      }
      else {
        let scores = {}
        for (let i = 0; i < this.imageTopic.length; i++) {
          if (this.imageTopic[i]['score']) {
            // scores.push({
            //   [this.imageTopic[i]['name']]: this.imageTopic[i]['score']
            // })
            scores[this.imageTopic[i]['name']] = this.imageTopic[i]['score']
          }
        }
        let editForm = {
          edu_paper_id: Number(this.$route.query.paper_id),
          edu_paper_answer_sheet_id: Number(this.$route.query.sheet_id),
          user_id: Number(this.form.uid),
          question_data: this.form.question_data,
          image_data: this.form.image_data,
          default_data: this.form.pq,
          // ticket_number: this.form.ticket_number,
          ticket_number: this.form.rn || this.form.rn_hand,
          scores: scores
        }
        const data = await confirmSheet(editForm)
        this.$message.success("操作成功")
        this.$bus.$emit('refreshList');
        this.$router.go(-1)

      }
      // console.log(editForm)
    },

    //获取答题卡
    async getDetail (sheetId, paperId, status, againData) {

      if (!againData) {
        let params = {
          edu_paper_answer_sheet_id: Number(sheetId),
        }
        this.dataReset()
        let data

        if (status == 1) {

          data = await sheetDatail(params)
        }
        else {
          let param = {
            edu_paper_answer_sheet_id: Number(sheetId),
            // paper_test_id: paperId
          }

          data = await singleAuditSheet(param)
          if (data.data.errmsg) {
            this.$message.error(data.data.errmsg)

          }


        }
        this.isRecognize = true

        this.dataReset()
        this.form = data.data
        console.log('this.form', this.form)
        if (status == 1) {
          this.form.rn = this.form.rn || this.form.rn_hand || this.form.ticket_number
          this.form.student_name = this.form.student_name
        }
      }
      // 重新上传赋值
      else {
        this.form = againData
      }


      // console.log(this.form)
      // if (this.form.img) {
      //   this.previewList.push(this.form.img)
      // } else
      if (this.form.image) {
        this.previewList.push(this.form.image)
      }
      if (this.form.image2) {
        this.previewList.push(this.form.image2)
      }
      //else if() {
      //   this.previewList.push(this.form.image)
      // }
      // console.log(" this.previewList", this.previewList)
      //拼接大题和abcd区域两部分图片
      for (let i in this.form.image_data) {
        let score = 0
        // 匹配分数
        for (let j in this.form.scores) {
          if (i == j) {
            score = this.form.scores[j]
          }
        }
        this.imageTopic.push({
          name: i,
          value: this.form.image_data[i],
          score: score
        })
      }
      // console.log('早上就是就是计算机技术', this.form)
      this.infoImg.push({
        name: 'student_name',
        value: this.form.student_name
      }, { name: 'rn', value: this.form.pq.rn || this.form.pq.rn_hand || this.form.ticket_number })
      // for (let i in this.form.pq) {
      //   if (i && this.form.pq[i].length > 0) {
      //     this.infoLength += 1
      //     this.infoImg.push({
      //       name: i,
      //       value: this.form.pq[i]
      //     })
      //   }
      // }

      // this.infoImg.length = 4
      this.ImgList = [...this.infoImg, ...this.imageTopic]
      this.content = this.ImgList[0].value

      this.previewImg = this.setPreview(this.content, this.previewImg)
      // console.log(this.content)
      // console.log('大题图片', this.ImgList)
    },

    // 切换题目内容
    topicContent (item, page, label) {
      this.content = item
      this.previewImg = this.setPreview(this.content, this.previewImg)
      if (page == this.infoLength + 1) {
        this.topicNumber = "选择题"
      }

      else {
        if (label) {
          this.topicNumber = `第${label}题`
        } else {
          this.topicNumber = ''
        }
      }

      this.currentPage = page
      this.topicActiveId = page
    },
    //分页切换页面
    currentChange (page) {
      if (page == this.infoLength + 1) {
        this.topicNumber = "选择题"
      }
      if (this.content = this.ImgList[page - 1]) {
        this.content = this.ImgList[page - 1].value
        this.previewImg = this.setPreview(this.content, this.previewImg)
        if (Number(this.ImgList[page - 1].name)) {
          this.topicNumber = `第${this.ImgList[page - 1].name}题`
        }
        else {
          this.topicNumber = ''
        }
      }
      this.topicActiveId = page

    },
    //数据重置
    dataReset () {
      this.previewList = []
      this.imageTopic = []
      this.infoImg = []
      this.ImgList = []
      this.previewImg = []
      this.content = undefined
      this.topicNumber = ''
    },
    showUploadDialog () {
      this.$refs.dialogRef.dialogVisible = true
      this.$refs.dialogRef.isAdd = 0
    },

    //设置预览图片数组
    setPreview (data, arr) {
      if (data && Array.isArray(data)) {
        arr = [...data]
      } else if (data) {
        arr = []
        arr.push(data)
      } else {
        arr = []
      }
      return arr
    },
    // 上传进度
    uploadProgress (progressEvent) {
      console.log(111)
      if (progressEvent.lengthComputable) {   //是否存在进度
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        this.startLoading = percentCompleted//标记进度条记时开始
        console.log('进度：', percentCompleted)
        // item.fileUploadPercent = percentCompleted;
        // this.$forceUpdate();
      }
    },


    //重新上传答题卡,子组件上传
    async uploadSubmit (type, image_type) {
      let randomProgress = Math.random() + ''
      let params = {
        edu_paper_id: this.$route.query.paper_id,
        edu_paper_answer_sheet_id: this.$route.query.sheet_id,
        image_type: image_type,
        type: type,
        progress: randomProgress,
        file_name: 'file'
      }
      try {
        this.sitv = setInterval(async () => {
          if (this.startLoading > 0) {
            let { data } = await uploadSheetSchedule({
              "progress": randomProgress,
              "test_paper_id": Number(this.$route.query.paper_id)
            })
            // console.log(data)
            this.$refs.dialogRef.percentage = Math.round(data.schedule)
            // count += 0.5
            // this.$refs.dialogRef.percentage += 15
            // console.log(200, count)
          }
        }, 1000);
        this.$refs.dialogRef.disabled = true
        const { data } = await uploadAgainSheet(params, 20, "image/*", this.uploadProgress, multiple)
        //  await this.getDetail(params.test_sheet_id, params.paper_test_id,)
        // await this.getDetail(params.test_sheet_id, params.paper_test_id, data.status, data)
        clearInterval(this.sitv)
        this.$set(this.form, 'image', data.image)
        this.$set(this.form, 'image2', data.image2)
        this.previewList = []
        this.previewList.push(this.form.image, this.form.image2)
        this.startLoading = 0
        this.$refs.dialogRef.percentage = 100
        this.$refs.dialogRef.status1 = 'success'
        this.$message.success("操作成功,请点击识别答题卡按钮识别答题卡")
        this.$refs.dialogRef.disabled = false
        this.$refs.dialogRef.handleClose()
      } catch (err) {
        // this.form = {}
        clearInterval(this.sitv)
        this.startLoading = 0
        this.$message.error("请重新上传")
        this.$refs.dialogRef.disabled = false
        this.dataReset()
        loadings.close()
        console.log("err", err)
      }
    }
  },
  destroyed () {
    // window.localStorage.removeItem("cardUrlObj")
  }

}
</script>

<style lang="scss" scoped>
#top {
  background: #ffffff;
}
#savebtn {
  width: 96%;
  /* float: right; */
  position: dis;
  top: fixed;
  /* position: fixed; */
  top: 235px;
  display: flex;
  height: 56px;
  padding: 0px var(--base-24, 24px);
  justify-content: flex-end;
  background: #ffffff;
  align-items: center;
  align-self: stretch;
}
#xzt {
  ::v-deep .el-row {
    // margin-bottom: 10px;
    display: inline-flex;
  }
}
.el-row {
  // margin-bottom: 10px;
  // display: inline-flex;
}
.btn_wrap {
  display: flex;
  .btn {
    padding: 0 10px;
    background: #409eff;
    border-radius: 4px;
    color: white;
    margin-right: 10px;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
  }
}
.image {
  display: flex;
  flex-direction: column;
  .again-btn {
    // display: flex;
    display: inline-block;
    margin-left: 30px;
    align-items: flex-end;
    .el-button {
      width: auto;
      padding: 0 15px;
    }
  }
  .is_active {
    border: 1px solid #95c7f9;
    box-shadow: inset #7e9ab7 -1px 0px 9px;
  }
  .label {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
    margin-left: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    // margin-top: 10px;
    .text {
      display: inline-block;
      padding: 8px 0;
      color: var(--text-111111, var(--neutral-color-111111, #111));
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
  }
  div {
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
  }
  .btn {
    margin-left: 20px;
  }
}

.priview {
  position: relative;
  color: #ccc;
  width: 460px;
  height: 353px;
  margin-left: 10px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  margin-right: 50px;
  text-align: center;
  line-height: 353px;
  cursor: pointer;

  div {
    position: absolute;
    background: red;
    opacity: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.topicImage {
  margin: 20px 10px 10px;
  margin-bottom: 0;
  height: calc(100vh - 575px);
  .label {
    margin: 20px 0;
  }
  .imageWrap {
    width: 1190px;
    // height: 282px;
    height: calc(100vh - 690px);
    max-height: 716px;
    border-radius: 13px;
    padding: 20px;
    border: 2px solid #d9d9d9;
    overflow: auto;
    img {
      max-width: 1150px;
    }

    .paperbg {
      background-repeat: no-repeat;
      background-size: 200%;
      width: 1150px;
      height: 1600px;
    }
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb {
    border-radius: 60px;
  }

  /* 滚动条没有滑块的轨道部分 */
  ::-webkit-scrollbar-track-piece {
    border-radius: 50px;
  }
}
::v-deep .el-image.priview img {
  // width: fit-content;
  width: auto;
  height: 100%;
}
::v-deep .el-form-item__label {
  font-size: 14px;
}
.active_topic {
  ::v-deep .el-form-item__label {
    // color: #409eff;
  }
}
.errorInfo {
  ::v-deep .el-input__inner {
    color: #ef5350;
    // background: #ef5350 !important;
    border: 1px solid #bf5957;
  }
}
.maybeError {
  ::v-deep .el-input__inner {
    color: #ffa726;
    // background: #ef5350 !important;
    border: 1px solid #ffa726;
  }
  // ::after {
  //   content: "识别错误";
  //   color: #ef5350;
  // }
}
.img_page {
  box-shadow: 0px 0px 10px #ddd;
}

.right {
  padding: 40px 30px 20px 20px;
}
.fixed_1 {
  position: fixed;
  right: 100000px;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgb(202, 202, 202);
  border-radius: 50px;
}

/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20px;
  background-color: rgb(247, 247, 247);
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>